import { Menu, Transition } from '@headlessui/react';
import Cookies from 'js-cookie';
import { Fragment } from 'react';
import { useNavigate } from 'react-router';
/* eslint-disable no-nested-ternary */
import CreditIcon from '@/assets/icons/credit-icon';
import LogOutIcon from '@/assets/icons/logout-icon';
import ManageIcon from '@/assets/icons/manage-icon';
import ProfileIcon from '@/assets/icons/profile-icon';
import settingIcon from '@/assets/icons/setting.svg';
import { useGetUserQuery, useLogoutMutation } from '@/features/auth/auth.api';
import { useCreatePortalSessionMutation } from '@/features/user/user.api';
import Spinner from '../spinner/spinner.component';

interface Props {
  closeModal?: () => void;
}

export default function SettingDropdown({ closeModal }: Props) {
  const token = Cookies.get('accessToken');
  const navigate = useNavigate();
  const [logOut] = useLogoutMutation();
  const { data: user } = useGetUserQuery(null, {
    skip: !token,
  });
  const [createPortalSession, { isLoading }] = useCreatePortalSessionMutation();

  if (isLoading) return <Spinner />;

  const handleSubmit = () => {
    if (!user?.stripe_customer_id) return;
    createPortalSession({ customerId: user?.stripe_customer_id });
  };

  const disabled = user?.stripe_customer_id === null;

  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button
          data-testid="setting-icon"
          className="hover:cursor-pointer w-4"
        >
          <img src={settingIcon} alt="setting icon" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-[100] absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    data-testid="profile-setting-button"
                    onClick={() => {
                      if (closeModal) closeModal();
                      navigate('/profile-setting');
                    }}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <ProfileIcon color={active ? 'white' : '#3b82f6'} />
                    <span
                      className={`ml-2 font-medium ${
                        active ? 'text-white' : 'text-gray-500'
                      }`}
                    >
                      Profile Settings
                    </span>
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    data-testid="manage-subscription-button"
                    onClick={handleSubmit}
                    disabled={disabled}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } ${
                      disabled && 'cursor-not-allowed'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <ManageIcon
                      color={active ? 'white' : disabled ? 'gray' : '#3b82f6'}
                    />
                    <span
                      className={`ml-2 font-medium ${
                        active ? 'text-white' : 'text-gray-500'
                      }`}
                    >
                      Manage Subscription
                    </span>
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    data-testid="buy-credits-button"
                    onClick={() => navigate('/buy-credits')}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <CreditIcon color={active ? 'white' : '#3b82f6'} />
                    <span
                      className={`ml-2 font-medium ${
                        active ? 'text-white' : 'text-gray-500'
                      }`}
                    >
                      Buy Credits
                    </span>
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    data-testid="logout-button"
                    onClick={() => logOut()}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <LogOutIcon color={active ? 'white' : '#3b82f6'} />
                    <span
                      className={`ml-2 font-medium ${
                        active ? 'text-white' : 'text-gray-500'
                      }`}
                    >
                      Log out
                    </span>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
