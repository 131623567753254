import ErrorIcon from '@/assets/icons/error-alert.svg';

interface IError {
  message: any;
  color: string;
  width?: string;
  testId?: string;
}

const ErrorAlert = ({ message, color, width, testId }: IError) => {
  return (
    <div
      className={`flex justify-center items-center pl-5 gap-2 mt-1 w-${width}`}
    >
      <img src={ErrorIcon} alt="error alert icon" className="w-3" />
      <p
        role="alert"
        className={`${color} text-xs text-center `}
        data-testid={testId}
      >
        {message}
      </p>
    </div>
  );
};

export default ErrorAlert;
