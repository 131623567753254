import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/store';

const initialState: KeywordGroupState = {
  KeywordGroup: [],
  activeGroup: null,
  loading: false,
};

const KeywordGroupSlice = createSlice({
  name: 'KeywordGroup',
  initialState,
  reducers: {
    setKeywordGroup: (state, action: PayloadAction<KeywordGroup[]>) => {
      state.KeywordGroup = action.payload;

      if (!state.activeGroup) {
        state.activeGroup = action.payload?.[0]?.keyword_group_id;
      }
    },

    addKeywordGroup: (state, action: PayloadAction<KeywordGroup>) => {
      state.KeywordGroup.push(action.payload);
    },

    removeKeywordGroup: (state, action: PayloadAction<number>) => {
      state.KeywordGroup = state.KeywordGroup.filter(
        (keyword) => keyword.keyword_group_id !== action.payload
      );

      if (state.activeGroup === action.payload) {
        state.activeGroup = state.KeywordGroup?.[0]?.keyword_group_id;
      }
    },

    updateKeywordGroup: (state, action: PayloadAction<UpdateGroup>) => {
      const index = state.KeywordGroup.findIndex(
        (keyword) => keyword.keyword_group_id === action.payload.id
      );

      state.KeywordGroup[index] = {
        ...state.KeywordGroup[index],
        ...action.payload,
      };
    },

    setActiveGroup: (state, action: PayloadAction<number | null>) => {
      state.activeGroup = action.payload;
    },

    setKeywordLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setActiveGroup,
  setKeywordGroup,
  addKeywordGroup,
  setKeywordLoading,
  removeKeywordGroup,
  updateKeywordGroup,
} = KeywordGroupSlice.actions;

export default KeywordGroupSlice.reducer;

export const selectKeywordGroup = (state: RootState) => state.KeywordGroup;
