import { format } from 'date-fns';
import { ActiveMenuItem } from '../../sidebar.config';

export const rpmValues = {
  START_VALUE: '4',
  END_VALUE: '25',
};

const firstUploadDateStart = format(new Date('09/01/2023'), 'MM/dd/yyyy');
const firstUploadDateEnd = format(new Date(), 'MM/dd/yyyy');

export type MenuItems = {
  id: string;
  Icon: React.FC<{ stroke?: string }>;
  label: string;
  link: string;
  activeItem?: ActiveMenuItem;
};

export interface IMenuItemProps {
  item: MenuItems;
  expanded: boolean;
}

export const defaultFilterParams = [
  { param: 'chHasShorts', value: 'false' },
  { param: 'chMonetized', value: 'true' },
  { param: 'chRpmStart', value: rpmValues.START_VALUE },
  { param: 'chRpmEnd', value: rpmValues.END_VALUE },
  { param: 'chFirstVideoUploadDateStart', value: firstUploadDateStart },
  { param: 'chFirstVideoUploadDateEnd', value: firstUploadDateEnd },
  { param: 'chMonthlyRevenueStart', value: '500' },
  { param: 'chMonthlyRevenueEnd', value: '100000' },
];
