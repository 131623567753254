import moment from 'moment-timezone';

interface IPackageType {
  latestSubscribedPackage: string | null;
  packageValidity: string | null;
}
export enum PackageValidityType {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
  CANCELLED = 'Cancelled',
  LIFETIME = 'Lifetime',
}
export enum SubscribedPackageType {
  BASIC = 'basic',
  PRO = 'pro',
  ADVANCED = 'advanced',
}
export const findPackageType = (
  user: IGetMeResponse | undefined,
  userVerifyTokenInfo: VerifyTokenResponse | undefined
) => {
  const subscriptionType: IPackageType = {
    latestSubscribedPackage: '',
    packageValidity: '',
  };
  if (user?.subscription_status === 'active') {
    const subscriptionStartDate = moment(
      user.subscription_current_period_start
    );
    const subscriptionEndDate = moment(user.subscription_current_period_end);

    if (subscriptionStartDate.isValid() && subscriptionEndDate.isValid()) {
      const remainingSubDays = subscriptionEndDate.diff(
        subscriptionStartDate,
        'days'
      );

      if (remainingSubDays >= 29 && remainingSubDays <= 31) {
        subscriptionType.packageValidity = PackageValidityType.MONTHLY;
      } else {
        subscriptionType.packageValidity = PackageValidityType.YEARLY;
      }
    }
    if (
      subscriptionStartDate.isValid() &&
      user.subscription_current_period_end === null
    ) {
      subscriptionType.packageValidity = PackageValidityType.LIFETIME;
    }
    if (userVerifyTokenInfo?.latestSubscribedPackage) {
      subscriptionType.latestSubscribedPackage =
        userVerifyTokenInfo?.latestSubscribedPackage;
    }
  }
  if (user?.subscription_status === 'canceled') {
    subscriptionType.packageValidity = PackageValidityType.CANCELLED;
    subscriptionType.latestSubscribedPackage = null;
  }

  return subscriptionType;
};
