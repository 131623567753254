import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';

const secretKey =
  'my-super-secret-key-which-is-very-long-so-that-it-will-be-hard-for-anyone-to-guess-it';

const persistConfig = {
  storage,
  version: 1,
  key: 'nexlev-web',
  whitelist: ['auth', 'watchList', 'keywordFilter'],
  transforms: [
    encryptTransform({
      secretKey,
      onError: (error: any) => {
        console.error(error);
      },
    }),
  ],
};

export default persistConfig;
