import * as Sentry from '@sentry/react';
import { hotjar } from 'react-hotjar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';

import AppRoutes from '@/routes';
import FallbackComponent from './components/placeholders/fallback-component';
import ErrorBoundary from './error-boundary';
import FilterProvider from './features/filter/filter-provider';
import useUserLocation from './hooks/use-user-location.hook';
import UnderMaintenance from './pages/maintenance.page';

const USER_ID = import.meta.env.VITE_HOTJAR_SITE_ID;
const VERSION = import.meta.env.VITE_HOTJAR_SITE_VERSION;
const UNDER_MAINTENANCE = import.meta.env.VITE_UNDER_MAINTENANCE || false;

const App: React.FC = () => {
  useUserLocation();
  hotjar.initialize(USER_ID, VERSION);

  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
      <FilterProvider>
        <ErrorBoundary>
          {UNDER_MAINTENANCE === 'true' ? <UnderMaintenance /> : <AppRoutes />}
          <ToastContainer />
        </ErrorBoundary>
      </FilterProvider>
    </Sentry.ErrorBoundary>
  );
};

export default Sentry.withProfiler(App);
