import * as Tooltip from '@radix-ui/react-tooltip';
import { Dispatch, ReactNode, SetStateAction } from 'react';

import useBreakpoint from '@/hooks/use-breakpoint.hook';

interface TooltipProps {
  tooltipContent: ReactNode;
  tooltipTrigger: ReactNode;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const TooltipComp = ({
  tooltipContent,
  tooltipTrigger,
  isVisible,
  setIsVisible,
}: TooltipProps) => {
  const { isMd } = useBreakpoint('md');

  return (
    <Tooltip.Provider delayDuration={100}>
      <Tooltip.Root
        onOpenChange={() => {
          setIsVisible((visible) => !visible);
        }}
        open={isVisible}
      >
        <Tooltip.Trigger asChild onClick={() => setIsVisible(true)}>
          {tooltipTrigger}
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className={`${
              isVisible ? 'opacity-100' : 'opacity-0'
            } transition-opacity duration-300 ease-in-out delay-200 `}
            sideOffset={5}
            side={isMd ? 'bottom' : 'bottom'}
            data-testid="tooltip-content"
          >
            {tooltipContent}
            <Tooltip.Arrow />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default TooltipComp;
