import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import HighlightedChannelsIcon from '@/assets/icons/highlighted-channels-icon';
import routesConfig from '@/config/routes.config';
import useFilterContext from '@/features/filter/use-filter-context';
import useActiveLink from '@/hooks/use-active-link';
import { IMenuItemProps, defaultFilterParams } from './constants';
import NoLinkNavItems from './no-link-nav-items';

const MenuItem = ({ item, expanded }: IMenuItemProps) => {
  const { id, Icon, label, link } = item;
  const navigate = useNavigate();
  const active = useActiveLink(link, false);
  const { newParams } = useFilterContext();

  newParams.delete('name');
  newParams.delete('keywordId');
  newParams.delete('tab');
  newParams.delete('activeGroup');
  newParams.delete('excludeRevealedKeywords');
  newParams.delete('excludeRevealedChannels');
  newParams.delete('chHighlightedOnly');
  newParams.delete('chName');
  newParams.delete('templateId');

  const setDefaultParam = (param: string, value: string) => {
    if (!newParams.get(param)) newParams.set(param, value);
  };

  const navigateWithParams = (sortByParam: string) => {
    newParams.set('sortBy', sortByParam);
    navigate(`${link}?${newParams.toString()}`);
  };

  const handleNavigate = () => {
    defaultFilterParams.forEach(({ param, value }) => {
      setDefaultParam(param, value);
    });

    if (link === routesConfig.KEYWORDS) {
      navigateWithParams('-filtered_keyword_avg_view_count_per_video');
    } else if (link === routesConfig.CHANNELS) {
      navigateWithParams('-ch_avg_view_count_per_video');
    } else if (link === routesConfig.SAVED) {
      navigate(`${link}?tab=channels`);
    } else {
      navigate(link);
    }

    window.scrollTo(0, 0);
  };

  if (!link) {
    return <NoLinkNavItems item={item} expanded={expanded} />;
  }

  return (
    <button
      id={id}
      className={classNames('flex items-center text-dark', {
        'bg-dark transition-all': active,
        'w-[90%] h-12 rounded-r-[1.5rem] justify-start pl-4 space-x-3':
          expanded,
        'w-full h-[3.75rem] flex-col justify-center space-y-1': !expanded,
        'transition duration-150 ease-in-out': true,
      })}
      onClick={handleNavigate}
      type="button"
      data-cy={`menu-item-${id}`}
    >
      <div className="flex justify-center items-center">
        <Icon stroke={active ? 'white' : undefined} />
        <HighlightedChannelsIcon
          className={classNames(
            {
              hidden: id !== 'ai-niche-finder' || expanded,
            },
            '-ml-2.5'
          )}
          stroke={active ? undefined : 'white'}
        />
      </div>

      <div className="flex justify-center items-center gap-2">
        <div
          className={classNames('font-semibold text-center antialiased', {
            'text-white': active,
            'text-[0.625rem] px-0.5': !expanded,
            'text-[0.8125rem]': expanded,
          })}
        >
          {label}
        </div>
        <HighlightedChannelsIcon
          className={classNames({
            hidden: id !== 'ai-niche-finder' || !expanded,
          })}
          stroke={active ? undefined : 'white'}
        />
      </div>
    </button>
  );
};

export default MenuItem;
