import { Navigate, Outlet, useLocation } from 'react-router-dom';

import routesConfig from '@/config/routes.config';
import { useGetUserQuery } from '@/features/auth/auth.api';

const SubscriptionWrapper = () => {
  const { data } = useGetUserQuery(null);
  const location = useLocation();

  if (data && !data?.is_lifetime && !data?.stripe_customer_id)
    return (
      <Navigate
        to={routesConfig.SUBSCRIPTION}
        state={{ from: location }}
        replace
      />
    );

  return <Outlet />;
};

export default SubscriptionWrapper;
