interface IiconProps {
  stroke?: string;
  height?: number;
  width?: number;
  props?: string;
}

const SavedIconSvg = ({
  stroke = '#161313',
  height = 20,
  width = 20,
  props,
}: IiconProps) => {
  return (
    <svg
      className={props}
      width={width}
      height={height}
      stroke={stroke}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 19L8 14L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V19Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default SavedIconSvg;
