/* eslint-disable react/no-array-index-key */
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import crown from '@/assets/icons/crown-icon.svg';
import LogoSmall from '@/assets/logo.svg';
import AvatarComponent from '@/components/avatar.component';
import NotificationDropdown from '@/components/dropdown/notification-dropdown';
import SettingDropdown from '@/components/dropdown/setting-dropdown';
import SearchInput from '@/components/inputs/search-input.component';
import { useGetUserQuery, useVerifyTokenQuery } from '@/features/auth/auth.api';

import SEARCH_TYPE from '@/components/inputs/constants';
import routesConfig from '@/config/routes.config';
import CreditsWallet from '@/features/subscription/credits-wallet.component';
import useBreakpoint from '@/hooks/use-breakpoint.hook';
import {
  PackageValidityType,
  findPackageType,
} from '@/util/findSubPackageType';
import PopupSetting from './popup-setting.component';
import SidebarConfig from './sidebar/sidebar.config';
import './style.css';
import SubscriptionEnd from './subscription-end.component';

const NavbarLayout = () => {
  const token = Cookies.get('accessToken');
  const [selectSearchType, setSelectSearchType] = useState(SEARCH_TYPE[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const { data } = useGetUserQuery(null, {
    skip: !token,
  });
  const { data: userVerifyTokenInfo } = useVerifyTokenQuery(null, {
    skip: !token,
  });

  const creditsDeducate = data?.credits;

  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const { isMd } = useBreakpoint('md');
  const [isSubscriptionEnd, setSubscriptionEnd] = useState(false);
  const userPackageType = findPackageType(data, userVerifyTokenInfo);

  useEffect(() => {
    setExpanded(SidebarConfig.getSidebarExpandedState(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (data && data?.subscription_status === 'cancelled') {
      setSubscriptionEnd(true);
    }
  }, [data]);

  const handleSubscriptionButton = () => {
    navigate(routesConfig.SUBSCRIPTION, {
      state: {
        fromUpgradeNotice: true,
      },
    });
  };

  return (
    <>
      {isSubscriptionEnd && SubscriptionEnd(isMd)}

      <div
        className={`flex flex-col ${
          data?.subscription_status !== 'active' &&
          data?.subscription_status !== 'trialing' &&
          ` fixed ${
            expanded
              ? 'sm:w-[calc(100%-14%)] w-full'
              : 'sm:w-[calc(100%-6%)] w-full'
          } `
        } `}
      >
        <nav className="w-full px-5 py-4 flex flex-col md:flex-row items-center justify-evenly space-y-6 first-letter:align-middle bg-nexlev-100 ">
          <div className="w-full md:hidden flex flex-row items-center justify-between align-middle">
            <AvatarComponent
              avatarArea="w-12 h-12 sm:h-20 sm:w-20"
              iconPosition="sm:w-7 sm:h-7 w-5 h-5"
              icon={crown}
              userName={`${data?.name} ${data?.family_name}`}
              img={data?.picture}
            />

            <button
              type="button"
              onClick={() => navigate('/')}
              className="w-1/2"
            >
              <img src={LogoSmall} className="w-32" alt="Logo" />
            </button>
            <PopupSetting />
          </div>
          <div className="w-full md:w-[70%]">
            <SearchInput
              selectType={selectSearchType}
              setSelectType={setSelectSearchType}
              selectOptions={SEARCH_TYPE}
              placeholder={`Search a ${selectSearchType.value}...`}
              onChange={(e) => setSearchTerm(e)}
            >
              {searchTerm}
            </SearchInput>
          </div>
          <div className="w-[30%] hidden md:flex flex-row items-center justify-end gap-4">
            {data?.is_beta_user ? null : (
              <div>
                {userPackageType?.packageValidity ===
                  PackageValidityType.MONTHLY ||
                (data?.subscription_status !== 'active' &&
                  data?.stripe_customer_id) ? (
                  <button
                    type="button"
                    className="bg-[#CFFF06] flex justify-center items-center p-2 rounded-[40px] "
                    onClick={handleSubscriptionButton}
                  >
                    <h3 className="text-black text-[10px] font-inter font-bold">
                      Buy Lifetime Membership
                    </h3>
                  </button>
                ) : null}
              </div>
            )}

            <CreditsWallet creditsDeducate={creditsDeducate} />
            <NotificationDropdown />
            <SettingDropdown />
          </div>
        </nav>
        <div className="bg-nexlev-100 w-full min-h-fit ">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default NavbarLayout;
