import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
/* eslint-disable react-hooks/exhaustive-deps */
import AlertModal from '@/components/modals/alert-modal';
import SuspenseLoader from '@/components/suspense-loader';
import routesConfig from '@/config/routes.config';
import { useGetUserQuery, useVerifyTokenQuery } from '@/features/auth/auth.api';
import { useCreatePortalSessionMutation } from '@/features/user/user.api';
import ErrorPage from '@/pages/error.page';

const PrivateWrapper = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const token = Cookies.get('accessToken');
  const [createSession] = useCreatePortalSessionMutation();

  // verify token
  const {
    data,
    refetch,
    isLoading,
    isError: verifyError,
  } = useVerifyTokenQuery(null, {
    skip: !token,
  });

  // get user data
  const {
    isError,
    refetch: refetchUser,
    data: user,
    isLoading: loadingUser,
  } = useGetUserQuery(null, {
    skip: !token,
  });

  useEffect(() => {
    if (
      data &&
      user &&
      !data?.hasActiveSubscription &&
      user?.stripe_subscription_id
    ) {
      setOpen(true);
    }
  }, [user, data]);

  useEffect(() => {
    if (token) {
      refetch();
      refetchUser();
    }
  }, [location.pathname]);

  // if is error, show error page
  if (isError || verifyError) return <ErrorPage />;

  // show loading spinner
  if (isLoading || loadingUser) return <SuspenseLoader />;

  // if user is not logged in, redirect to login page
  if (!token)
    return (
      <Navigate to={routesConfig.LOGIN} state={{ from: location }} replace />
    );

  // redirect to stripe portal for renewal
  const onSubmit = () => {
    if (!user?.stripe_customer_id) return;
    createSession({ customerId: user?.stripe_customer_id });
    setOpen(false);
  };

  return (
    <>
      <Outlet />
      <AlertModal
        isOpen={open}
        action={onSubmit}
        btnTxt="Renew Subscription"
        close={() => setOpen(false)}
        title="Your subscription has expired!"
        subtitle1="Please renew your subscription to continue using the app. If you don't renew your subscription, you will lose access to the app."
      />
    </>
  );
};

export default PrivateWrapper;
