import Cookies from 'js-cookie';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import routesConfig from '@/config/routes.config';
import { useGetUserQuery } from '@/features/auth/auth.api';

const PublicWrapper = () => {
  const location = useLocation();
  const token = Cookies.get('accessToken');

  useGetUserQuery(null, {
    skip: !token,
  });

  if (token)
    return (
      <Navigate to={routesConfig.HOME} state={{ from: location }} replace />
    );

  return <Outlet />;
};

export default PublicWrapper;
