interface IButtonProps {
  text: string;
  bgColor?: string;
  bgHoverColor?: string;
  bgActiveColor?: string;
  textColor?: string;
  rounded?: string;
  onClickHandle?: () => void;
  height?: string;
  width?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button = ({
  text,
  bgColor,
  bgHoverColor,
  bgActiveColor,
  onClickHandle,
  textColor = 'bg-gray-600',
  rounded = 'custom-lg',
  height = '',
  width = '',
  type = 'submit',
}: IButtonProps) => {
  const handleClick = (e: any) => {
    if (onClickHandle) {
      onClickHandle();
    }
  };
  return (
    <div className="flex space-x-2 justify-center items-center w-full">
      <button
        type="submit"
        onClick={(e) => handleClick(e)}
        data-mdb-ripple="true"
        data-mdb-ripple-color="light"
        data-testid={`${text.toLowerCase()}-button`}
        className={`w-full inline-block px-6 py-2.5 ${bgColor} text-${textColor} active:opacity-[60%] text-center font-medium text-xs leading-tight rounded-${rounded}  shadow-md hover:${bgHoverColor} hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:${bgActiveColor} active:shadow-lg transition duration-150 ease-in-out w-${width} h-${height}`}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
