interface IconProps {
  stroke?: string;
  height?: number;
  width?: number;
  props?: string;
}

const CustomKeywordsSvg = ({
  stroke = '#161313',
  height = 20,
  width = 20,
  props,
}: IconProps) => {
  return (
    <svg
      className={props}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 8.09766V0.75H8.09766V8.09766H0.75ZM2.59766 2.59766V6.25H6.25V2.59766H2.59766ZM9.90234 0.75H17.25V8.09766H9.90234V0.75ZM15.4023 6.25V2.59766H11.75V6.25H15.4023ZM0.75 17.25V9.90234H8.09766V17.25H0.75ZM2.59766 11.75V15.4023H6.25V11.75H2.59766ZM14.5 9.90234V12.6523H17.25V14.5H14.5V17.25H12.6523V14.5H9.90234V12.6523H12.6523V9.90234H14.5Z"
        fill={stroke}
      />
    </svg>
  );
};
export default CustomKeywordsSvg;
