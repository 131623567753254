import classNames from 'classnames';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';

import CrownIcon from '@/assets/icons/crown-icon.svg';
import SuspenseLoader from '@/components/suspense-loader';
import { useVerifyTokenQuery } from '@/features/auth/auth.api';

interface Props {
  roles: string[];
  children: React.ReactNode;
}

function handleCopy(event: React.ClipboardEvent) {
  event.preventDefault();
  // eslint-disable-next-line no-alert
  alert('Copying text is not allowed!');
}

const BlurredAuthWrapper = ({ roles, children }: Props) => {
  const token = Cookies.get('accessToken');
  const [isBlurred, setIsBlurred] = React.useState(false);
  const { data, isLoading } = useVerifyTokenQuery(null, {
    skip: !token,
  });

  useEffect(() => {
    if (
      (data && !roles.includes(data.latestSubscribedPackage)) ||
      (data && !data.hasActiveSubscription)
    ) {
      setIsBlurred(true);
    } else {
      setIsBlurred(false);
    }
  }, [data, roles]);

  useEffect(() => {
    const handleTab = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        e.preventDefault();
      }
    };
    if (isBlurred === true) document.addEventListener('keydown', handleTab);

    return () => {
      if (isBlurred === true)
        document.removeEventListener('keydown', handleTab);
    };
  }, [isBlurred]);

  if (isLoading) return <SuspenseLoader />;

  return (
    <div
      onCopy={isBlurred ? handleCopy : undefined}
      className={`min-h-fit bg-nexlev-100 relative ${
        isBlurred && 'select-none'
      }`}
      data-testid="blurred-auth-wrapper"
    >
      <div
        className={classNames(
          'w-[80%] md:h-24 h-16 z-50 flex items-center absolute top-0 bottom-0 left-0 right-0 m-auto bg-[#5C5050] rounded-custom-lg text-white',
          { hidden: !isBlurred }
        )}
      >
        <button
          className="w-full md:px-5 flex justify-center items-center"
          type="button"
        >
          <div className="flex space-x-2">
            <img src={CrownIcon} alt="crown-icon" />
            <p className="leading-[10] md:text-base text-sm">
              Upgrade Your Subscription
            </p>
          </div>
        </button>
      </div>
      <div
        className={classNames({
          'blur-sm pointer-events-none touch-none': isBlurred,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default BlurredAuthWrapper;
