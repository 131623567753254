import trending_flat from '@/assets/icons/trending_flat.svg';
import Logo from '@/assets/nexlev-logo.svg';

const SubscriptionEnd = (isMd: boolean) => {
  return (
    <div
      className={`fixed top-0 right-0 h-screen z-[500] overflow-y-auto ${
        !isMd && 'backdrop-blur-xl'
      } `}
    >
      <div className="w-full h-screen flex justify-between items-center">
        <div className="sm:flex hidden w-[32vw] h-screen bg-[#000000d5] backdrop-blur-sm" />
        <div
          className={`flex flex-col items-center text-center sm:w-[67vw] h-screen justify-center ${
            isMd ? 'backdrop-blur-xl' : 'px-2 overflow-y-scroll'
          }`}
        >
          <img src={Logo} className="w-40" alt="Logo" />
          <p className="sm:text-[3rem] text-[2.5rem] font-semibold leading-[75px] text-[#161313] mt-10">
            Your subscription has expired.
            <br /> Please renew your subscription <br /> to continue using
            <span className="font-extrabold"> NexLev</span>
          </p>
          <button
            className="bg-[#161313] text-white py-4 px-8 rounded-full flex items-center gap-3 mt-10"
            type="button"
          >
            <span>Upgrade Package</span>
            <img src={trending_flat} alt="right arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionEnd;
