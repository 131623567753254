interface IconProps {
  stroke?: string;
  height?: number;
  width?: number;
  props?: string;
}

const ChannelsIconSvg = ({
  stroke = '#161313',
  height = 20,
  width = 20,
  props,
}: IconProps) => {
  return (
    <svg
      className={props}
      width={width}
      height={height}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 2.31836H2.75V7.125H14.25V2.31836ZM14.25 0.431641C15.2832 0.431641 16.1816 1.28516 16.1816 2.31836V7.125C16.1816 8.1582 15.2832 9.05664 14.25 9.05664H2.75C1.7168 9.05664 0.818359 8.1582 0.818359 7.125V2.31836C0.818359 1.28516 1.7168 0.431641 2.75 0.431641H14.25ZM14.25 12.875H2.75V17.6816H14.25V12.875ZM14.25 10.9434C15.2832 10.9434 16.1816 11.8418 16.1816 12.875V17.6816C16.1816 18.7148 15.2832 19.5684 14.25 19.5684H2.75C1.7168 19.5684 0.818359 18.7148 0.818359 17.6816V12.875C0.818359 11.8418 1.7168 10.9434 2.75 10.9434H14.25Z"
        fill={stroke}
      />
    </svg>
  );
};
export default ChannelsIconSvg;
