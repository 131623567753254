import { useMediaQuery } from 'react-responsive';
import tailwindConfig from 'tailwind-config';
import resolveConfig from 'tailwindcss/resolveConfig';

interface Breakpoints {
  [key: string]: string | string[];
}

const fullConfig = resolveConfig(tailwindConfig);
const breakpoints: Breakpoints =
  (fullConfig?.theme?.screens as Breakpoints) || {};

export default function useBreakpoint<K extends string>(breakpointKey: K) {
  const bool = useMediaQuery({
    query: `(min-width: ${breakpoints[breakpointKey]})`,
  });

  const capitalizedKey =
    (breakpointKey[0] as string).toUpperCase() +
    (breakpointKey as string).substring(1);
  type Key = `is${Capitalize<string>}`;
  return {
    [`is${capitalizedKey}`]: bool,
  } as Record<Key, boolean>;
}
