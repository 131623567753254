interface IconProps {
  stroke?: string;
  height?: number;
  width?: number;
  props?: string;
}

const KeywordsIconSvg = ({
  stroke = '#161313',
  height = 20,
  width = 20,
  props,
}: IconProps) => {
  return (
    <svg
      className={props}
      width={width}
      height={height}
      stroke={stroke}
      viewBox=" 0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 14.9979V6.99795C18.9996 6.64722 18.9071 6.30276 18.7315 5.99911C18.556 5.69546 18.3037 5.44331 18 5.26795L11 1.26795C10.696 1.09241 10.3511 1 10 1C9.64893 1 9.30404 1.09241 9 1.26795L2 5.26795C1.69626 5.44331 1.44398 5.69546 1.26846 5.99911C1.09294 6.30276 1.00036 6.64722 1 6.99795V14.9979C1.00036 15.3487 1.09294 15.6931 1.26846 15.9968C1.44398 16.3004 1.69626 16.5526 2 16.7279L9 20.7279C9.30404 20.9035 9.64893 20.9959 10 20.9959C10.3511 20.9959 10.696 20.9035 11 20.7279L18 16.7279C18.3037 16.5526 18.556 16.3004 18.7315 15.9968C18.9071 15.6931 18.9996 15.3487 19 14.9979Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.875 12.1363C5.54545 12.1363 5.26278 12.0213 5.02698 11.7912C4.79403 11.5582 4.67897 11.2756 4.68181 10.9432C4.67897 10.6193 4.79403 10.3423 5.02698 10.1122C5.26278 9.88209 5.54545 9.76703 5.875 9.76703C6.1875 9.76703 6.46306 9.88209 6.7017 10.1122C6.94318 10.3423 7.06534 10.6193 7.06818 10.9432C7.06534 11.1648 7.0071 11.3665 6.89346 11.5483C6.78267 11.7273 6.63778 11.8707 6.4588 11.9787C6.27983 12.0838 6.08522 12.1363 5.875 12.1363Z"
        fill={stroke}
      />
      <path
        d="M9.985 12.1363C9.65545 12.1363 9.37278 12.0213 9.13698 11.7912C8.90403 11.5582 8.78897 11.2756 8.79181 10.9432C8.78897 10.6193 8.90403 10.3423 9.13698 10.1122C9.37278 9.88209 9.65545 9.76703 9.985 9.76703C10.2975 9.76703 10.5731 9.88209 10.8117 10.1122C11.0532 10.3423 11.1753 10.6193 11.1782 10.9432C11.1753 11.1648 11.1171 11.3665 11.0035 11.5483C10.8927 11.7273 10.7478 11.8707 10.5688 11.9787C10.3898 12.0838 10.1952 12.1363 9.985 12.1363Z"
        fill={stroke}
      />
      <path
        d="M14.095 12.1363C13.7655 12.1363 13.4828 12.0213 13.247 11.7912C13.014 11.5582 12.899 11.2756 12.9018 10.9432C12.899 10.6193 13.014 10.3423 13.247 10.1122C13.4828 9.88209 13.7655 9.76703 14.095 9.76703C14.4075 9.76703 14.6831 9.88209 14.9217 10.1122C15.1632 10.3423 15.2853 10.6193 15.2882 10.9432C15.2853 11.1648 15.2271 11.3665 15.1135 11.5483C15.0027 11.7273 14.8578 11.8707 14.6788 11.9787C14.4998 12.0838 14.3052 12.1363 14.095 12.1363Z"
        fill={stroke}
      />
    </svg>
  );
};
export default KeywordsIconSvg;
