import { Dispatch, SetStateAction, createContext } from 'react';

// ----------------------------------------------------------------------

type FilterContextType = {
  newParams: URLSearchParams;
  searchParams: URLSearchParams;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setParams: (key: string, value?: string) => void;
  appendParams: (key: string, value: string) => void;
  setSearchParams: (params: URLSearchParams) => void;
};

const FilterContext = createContext({} as FilterContextType);

export default FilterContext;
