/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import SuspenseLoader from '@/components/suspense-loader';
import routesConfig from '@/config/routes.config';
import { useGetUserQuery, useVerifyTokenQuery } from '@/features/auth/auth.api';
import ErrorPage from '@/pages/error.page';

const AdminLayout = () => {
  const location = useLocation();
  const token = Cookies.get('accessToken');
  const ADMIN_ROLE = 'admin';

  const {
    data,
    refetch,
    isLoading,
    isError: verifyError,
  } = useVerifyTokenQuery(null, {
    skip: !token,
  });

  const {
    isError,
    refetch: refetchUser,
    data: user,
    isLoading: loadingUser,
  } = useGetUserQuery(null, {
    skip: !token,
  });

  useEffect(() => {
    if (token) {
      refetch();
      refetchUser();
    }
  }, [location.pathname]);

  if (isError || verifyError) return <ErrorPage />;

  if (isLoading || loadingUser) return <SuspenseLoader />;

  if (data?.role !== ADMIN_ROLE && user?.role_name !== ADMIN_ROLE)
    return (
      <Navigate to={routesConfig.LOGIN} state={{ from: location }} replace />
    );

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AdminLayout;
