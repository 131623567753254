import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

const awsBaseQuery = fetchBaseQuery({
  baseUrl: 'https://hyl09xw3g8.execute-api.eu-central-1.amazonaws.com',
  prepareHeaders: (headers) => {
    const token = Cookies.get('accessToken');
    if (token) headers.set('authorization', `Bearer ${token}`);

    return headers;
  },
});

export default awsBaseQuery;
