import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable  import/export */
/* eslint-disable  array-callback-return */
import { RootState } from '@/store/store';

const initialState = {
  customKeywords: <CustomKeyword[]>[],
  xTotal: 0,
};

export const customKeywordsSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCustomKeywords: (
      state,
      { payload: { data } }: PayloadAction<{ data: CustomKeyword[] }>
    ) => {
      if (data.length > 0) {
        data.map((keyword) => {
          if (
            !state.customKeywords.some(
              (k) => k.customKeywordId === keyword.customKeywordId
            )
          )
            state.customKeywords.push(keyword);
        });
      }
    },
    setTotalCount: (
      state,
      { payload: { xTotalCount } }: PayloadAction<{ xTotalCount: number }>
    ) => {
      state.xTotal = xTotalCount;
    },

    removeCustomKeyword: (
      state,
      { payload: { id } }: PayloadAction<{ id: number }>
    ) => {
      const index = state.customKeywords.findIndex(
        (keyword) => keyword.customKeywordId === id
      );
      if (index !== -1) {
        state.customKeywords.splice(index, 1);
      }
    },
  },
});

export default customKeywordsSlice.reducer;

export const { setCustomKeywords, setTotalCount, removeCustomKeyword } =
  customKeywordsSlice.actions;

export const CustomKeywords = (state: RootState) => state.CustomKeywords;

export default interface IcustomKeywordsState {
  customKeywords: CustomKeyword[];
  xTotal: number;
}
