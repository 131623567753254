/* eslint-disable global-require */
/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  safelist: ['hover:bg-gray-700', 'active:bg-gray-600'],
  theme: {
    screens: {
      sm: '640px',
      // => @media (min-width: 640px) { ... }

      md: '780px',
      // => @media (min-width: 768px) { ... }

      lg: '1020px',
      // => @media (min-width: 1024px) { ... }

      xl: '1120px',

      xxl: '1400px',
    },
    fontFamily: {
      inter: ['Intro-Script', 'sans-serif'],
    },
    extend: {
      fontFamily: {
        poppins: ['Poppins', 'sans-serif'],
        'poppins-bold': ['Poppins Bold', 'sans-serif'],
        jakarta: ['Plus Jakarta Sans', 'sans-serif'],
      },
      borderRadius: {
        'custom-md': '0.625rem',
        'custom-lg': '1.25rem',
      },
      colors: {
        'nexlev-100': '#ECECF5',
        'nexlev-200': '#D6D6E5',
        'nexlev-300': '#D5D5E9',
        'nexlev-600': '#FF6006',
        'nexlev-error': '#FF6006',
        skycyan: '#02D5E0',
        'yellow-apple': '#CFFF06',
        sunset: '#FFA500',
        darkgray: '#524F4D',
        dark: '#161313',
        'deep-blue': '#1631A9',
        'dark-blue': '#0b228b',
      },
      zIndex: {
        100: '100',
      },
    },
  },
  plugins: [
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('tailwind-scrollbar')({ nocompatible: true }),
  ],
};
