interface IconProps {
  stroke?: string;
  height?: number;
  width?: number;
  props?: any;
}

const XMarkIcon = ({
  stroke = '#fff',
  height = 20,
  width,
  props,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={stroke}
      className={props}
      height={height}
      width={width}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};
export default XMarkIcon;
