interface IconProps {
  stroke?: string;
  height?: number;
  width?: number;
  props?: string;
  fill?: string;
}

const RpmIconSvg = ({
  stroke,
  height = 20,
  width = 20,
  props,
  fill = '#161313',
}: IconProps) => {
  return (
    <svg
      className={props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.125 5.18164C21.1582 5.18164 22.0566 6.03516 22.0566 7.06836C22.0566 8.46094 20.6641 9.26953 19.6309 8.95508L16.2168 12.3242C16.5312 13.3574 15.7676 14.75 14.375 14.75C12.9824 14.75 12.2188 13.3574 12.5332 12.3242L10.0625 9.89844C9.79297 9.98828 9.38867 9.98828 9.07422 9.89844L4.7168 14.2559C5.03125 15.2891 4.26758 16.6816 2.875 16.6816C1.8418 16.6816 0.943359 15.7832 0.943359 14.75C0.943359 13.3574 2.33594 12.5938 3.36914 12.9082L7.72656 8.55078C7.41211 7.51758 8.17578 6.125 9.56836 6.125C10.9609 6.125 11.7695 7.51758 11.4551 8.55078L13.8809 10.9766C14.1504 10.8867 14.5996 10.8867 14.8691 10.9766L18.2832 7.5625C17.9688 6.5293 18.7324 5.18164 20.125 5.18164ZM14.375 6.125L13.4766 4.14844L11.5 3.25L13.4766 2.35156L14.375 0.375L15.2734 2.35156L17.25 3.25L15.2734 4.14844L14.375 6.125ZM3.36914 8.05664L2.875 6.125L0.943359 5.63086L2.875 5.18164L3.36914 3.25L3.81836 5.18164L5.75 5.63086L3.81836 6.125L3.36914 8.05664Z"
        fill={stroke}
      />
    </svg>
  );
};
export default RpmIconSvg;
