import Spinner from '@/components/spinner/spinner.component';

interface IKeywordSearchResultProps {
  keywordData: SearchKeyword[];
  isLoading?: boolean;
  selected: string;
  selectedIndex: number;
  onClick: (label: SearchKeyword) => void;
}

const KeywordSearchResult = ({
  keywordData,
  isLoading = false,
  selected,
  selectedIndex,
  onClick,
}: IKeywordSearchResultProps) => {
  if (isLoading)
    return (
      <button
        type="button"
        className="w-fit flex justify-evenly items-center pl-4"
        disabled
      >
        <Spinner />
        <span className="ml-1">Loading...</span>
      </button>
    );

  return (
    <>
      {keywordData?.map((label, index) => (
        <div key={label.keyword_id} className="block">
          <button
            type="button"
            data-testid="keyword-search-result"
            className={`py-2 px-4 w-full text-left hover:bg-gray-100 ${
              selected === label.name && 'bg-gray-100'
            } ${selectedIndex === index && 'bg-gray-100'}`}
            onClick={() => onClick(label)}
          >
            {label.name}
          </button>
        </div>
      ))}

      {keywordData?.length === 0 && (
        <p data-testid="no-keyword-found" className="px-4 w-full text-left">
          No keyword found
        </p>
      )}
    </>
  );
};

export default KeywordSearchResult;
