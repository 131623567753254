import classNames from 'classnames';
import { IMenuItemProps } from './constants';

const NoLinkNavItems = ({ item, expanded }: IMenuItemProps) => {
  const { id, Icon, label } = item;
  return (
    <button
      id={id}
      className={classNames({
        'h-10 rounded-r-custom-lg justify-start pl-4 space-x-3': expanded,
        'w-full h-[55px] flex-col justify-center space-y-1': !expanded,
        'transition duration-150 ease-in-out flex items-center text-dark relative':
          true,
      })}
      type="button"
    >
      <Icon />
      <p
        className={classNames(
          'text-[0.65rem] font-bold text-center antialiased flex items-center md:space-x-1 lg:space-x-3 flex-wrap justify-center',
          {
            hidden: !expanded,
            'text-black font-bold': !expanded,
          }
        )}
      >
        <span className="block">{label}</span>
        <span className="bg-[#FF6006] block text-white px-2 py-1 rounded-md">
          Coming soon
        </span>
      </p>

      <span
        className={classNames({
          hidden: expanded,
          'bg-[#FF6006] text-white p-1 px-[5px] rounded-md text-[0.43rem] absolute top-[-15px] right-[12px] text-left':
            true,
        })}
      >
        COMING
        <br /> SOON
      </span>
    </button>
  );
};

export default NoLinkNavItems;
