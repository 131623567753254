import { combineReducers } from '@reduxjs/toolkit';

import ChannelGroup from '@/features/channel/channel-group/channel-group.slice';
import NexLevAI from '@/features/chatGPT/nexlev-ai.slice';
import CustomKeywords from '@/features/custom-keyword/custom-keywords.slice';
import KeywordGroup from '@/features/keyword/keyword-group/keyword-group.slice';
import baseAPI from './base.api';
import credential from '@/features/credential/credential.slice';

// Here you can add all your reducers
const combinedReducers = combineReducers({
  NexLevAI,
  credential,
  ChannelGroup,
  KeywordGroup,
  CustomKeywords,
  [baseAPI.reducerPath]: baseAPI.reducer,
});

export default combinedReducers;
