import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import baseAPI from '@/store/base.api';
import { cookieConfig } from '@/store/dev.nexLev';
import LoggedOut from '@/util/log-out';

export const authAPI = baseAPI.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<AuthResponse, AuthCredentials>({
      query: (credentials) => ({
        url: '/v1/auth/token',
        method: 'POST',
        body: credentials,
      }),

      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          // wait for the query to finish
          const { data } = await queryFulfilled;

          const accessToken = data.AccessToken;
          const refreshToken = data.RefreshToken;

          Cookies.set('accessToken', accessToken, cookieConfig);

          Cookies.set('refreshToken', refreshToken, cookieConfig);

          // call the getUser endpoint to get the user data
          dispatch(authAPI.endpoints.getUser.initiate(null));
        } catch (error: any) {
          console.log(error);

          const message = error?.error?.data || 'Something Went Wrong';

          toast.error(message);
        }
      },
    }),

    register: build.mutation<SignupResponse, SignupCredentials>({
      query: (credentials) => ({
        url: '/v1/users',
        method: 'POST',
        body: credentials,
      }),

      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          // wait for the query to finish
          await queryFulfilled;

          const message = 'Registration Successful';

          toast.success(message);

          const { email } = credentials;

          localStorage.setItem('email', email);
        } catch (error: any) {
          console.log(error);

          const message = error?.error?.data || 'Something Went Wrong';

          toast.error(message);
        }
      },
    }),

    confirmUser: build.mutation<ConfirmUserResponse, ConfirmUserCredentials>({
      query: (body) => {
        return {
          body,
          method: 'POST',
          url: '/v1/users/verify',
        };
      },

      transformErrorResponse(baseQueryReturnValue) {
        return baseQueryReturnValue.data;
      },
    }),

    resendCode: build.mutation<void, string>({
      query: (email) => {
        return {
          method: 'POST',
          body: { email },
          url: '/v1/users/resend-verification-code',
        };
      },

      transformErrorResponse(error) {
        const message = error?.data || 'Something Went Wrong';

        return message;
      },
    }),

    logout: build.mutation<void, void>({
      query: () => ({
        url: '/v1/auth/token',
        method: 'DELETE',
      }),

      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          // wait for the query to finish
          await queryFulfilled;

          // dispatch loggedOut action
          dispatch(baseAPI.util.resetApiState());
          LoggedOut();
        } catch (error) {
          toast.error('Something Went Wrong');
        }
      },
    }),

    refreshToken: build.mutation({
      query: () => ({
        url: '/auth/refresh-token',
        method: 'POST',
      }),
    }),

    updateUser: build.mutation<void, IUpdatedUser>({
      query: (updatedUser) => ({
        url: '/v1/users/profile',
        method: 'PUT',
        body: updatedUser,
      }),

      invalidatesTags: ['UpdateUser'],

      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          toast.success('Profile Updated Successfully');
        } catch (error: any) {
          const message = error?.error?.data || 'Something Went Wrong';

          toast.error(message);
        }
      },
    }),

    verifyNewEmail: build.mutation<void, string>({
      query: (code) => ({
        url: '/v1/users/verify-new-email',
        method: 'POST',
        body: { code },
      }),
      invalidatesTags: ['UpdateUser'],
      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          toast.success('Email Was Confirmed');
        } catch (error: any) {
          const message = error?.error?.data || 'Something Went Wrong';

          toast.error(message);
        }
      },
    }),

    getUser: build.query<IGetMeResponse, null>({
      query: () => '/v1/users/me',

      providesTags: ['UpdateUser'],

      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          // set account_id cookie
          Cookies.set('account_id', data.account_id, cookieConfig);
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    forgetPassword: build.mutation({
      query: (credentials) => ({
        url: '/v1/users/forgot-password',
        method: 'POST',
        body: credentials,
      }),

      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          // wait for the query to finish
          await queryFulfilled;

          toast.success('Password Reset Link Sent');

          console.log('/v1/users/forgot-password');

          // setEmail action
          Cookies.set('email', credentials.email);
        } catch (error: any) {
          console.log(error);

          const message = error?.error?.data || 'Something Went Wrong';

          toast.error(message);
        }
      },
    }),

    resetPassword: build.mutation<null, ResetPasswordCredential>({
      query: (credentials) => ({
        url: '/v1/users/confirm-new-password',
        method: 'POST',
        body: credentials,
      }),

      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          // wait for the query to finish
          await queryFulfilled;

          toast.success('Password Reset Successful');
        } catch (error: any) {
          console.log(error);

          const message = error?.error?.data || 'Something Went Wrong';

          if (message === 'Unrecognizable lambda output') {
            toast.success('Set New Password Successfully');
          } else {
            toast.error(message);
          }
        }
      },
    }),

    changePassword: build.mutation<ChangePasswordCredential, void>({
      query: (credentials) => ({
        url: '/v1/users/change-password',
        method: 'POST',
        body: credentials,
      }),

      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          toast.success('Password Successfully Changed');
        } catch (error: any) {
          const message = error?.error?.data || 'Something Went Wrong';

          toast.error(message);
        }
      },
    }),

    verifyToken: build.query<VerifyTokenResponse, null>({
      query: () => ({
        url: '/v1/auth/token',
        method: 'GET',
        skipToken: true,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useResendCodeMutation,
  useConfirmUserMutation,
  useRefreshTokenMutation,
  useResetPasswordMutation,
  useForgetPasswordMutation,
  useChangePasswordMutation,
  useUpdateUserMutation,
  useVerifyNewEmailMutation,
  useVerifyTokenQuery,
} = authAPI;
