/* eslint-disable no-restricted-syntax */
import {
  removeCustomKeyword,
  setCustomKeywords,
  setTotalCount,
} from '@/features/custom-keyword/custom-keywords.slice';
import baseAPI from '@/store/base.api';

const keywordAPI = baseAPI.injectEndpoints({
  endpoints: (build) => ({
    getKeywords: build.query<SearchKeyword[], string>({
      query: (query) => `/v1/keywords${`?${query}`}`,
    }),

    getKeywordsWithStats: build.query<KwAPIRes, string>({
      query: (query) => {
        const screenHeight = window.innerHeight;

        let limit = 5;

        if (screenHeight > 1400) limit = 10;
        if (screenHeight > 2900) limit = 15;

        return `/v1/keywords/stats?limit=${limit}&${query}`;
      },

      transformResponse: (keywords: Keyword[], meta: any) => {
        const xTotalCount = meta.response.headers.get('x-total-count') || 0;

        const uniqueKeywords = new Set(keywords.map((kw) => kw.keyword_id));

        const filteredKeywords = keywords.filter((kw) => {
          if (uniqueKeywords.has(kw.keyword_id)) {
            uniqueKeywords.delete(kw.keyword_id);
            return true;
          }
          return false;
        });

        return { keywords: filteredKeywords, xTotalCount };
      },

      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          // invalid endpoint to trigger refetch /v1/users/me
          dispatch(keywordAPI.util.invalidateTags(['UpdateUser']));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    getMoreKeywordsWithStats: build.query<Keyword[], MoreQueries>({
      query: ({ query, page }) =>
        `/v1/keywords/stats?page=${page}${`&${query}`}`,

      onQueryStarted: async ({ query }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          // invalid endpoint to trigger refetch /v1/users/me
          dispatch(keywordAPI.util.invalidateTags(['UpdateUser']));

          if (data.length !== 0) {
            dispatch(
              keywordAPI.util.updateQueryData(
                'getKeywordsWithStats',
                query,
                (draft) => {
                  const Ids = new Set(
                    draft?.keywords.map((kw) => kw.keyword_id)
                  );

                  for (const kw of data) {
                    if (!Ids.has(kw.keyword_id)) {
                      draft?.keywords.push(kw);
                      Ids.add(kw.keyword_id);
                    }
                  }

                  return draft;
                }
              )
            );
          }
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    GetSavedKeywordsSummary: build.query<KeywordSummary[], void>({
      query: () => '/v1/keywords/saved?limit=5',
      providesTags: ['savedKeywords'],
    }),

    getPopularKeywords: build.query<PopularKeyword[], void>({
      query: () => '/v1/keywords/populars?limit=50',
    }),

    getSavedKeywords: build.query<Keyword[], void>({
      query: () => '/v1/keywords/saved',
    }),

    CreateCustomKeyword: build.mutation<void, string>({
      query: (name) => ({
        url: '/v1/keywords/custom',
        method: 'POST',
        body: {
          name,
        },
      }),
      invalidatesTags: ['CustomKeywords', 'NewCustomKeywords'],

      transformErrorResponse({ data }) {
        let message = 'Something Went Wrong';

        if (data) {
          if (typeof data === 'string') {
            message = data;
          } else if (typeof data === 'object') {
            message = (data as any)?.message;
          }
        }

        if (message.includes('already exists')) {
          message =
            'This keyword already exists within the system! Please use the search bar.';
        }

        return message;
      },
    }),

    getCustomKeywords: build.query<CustomKeywordResponse, string>({
      query: (query) => ({
        url: `/v1/keywords/custom?${query}&limit=20`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['CustomKeywords', 'DeleteCustomKeyword'],

      transformResponse: (response: CustomKeyword[], meta: any) => {
        const xTotalCount = meta.response.headers.get('x-total-count');
        return { response, xTotalCount };
      },
      onQueryStarted: async (query, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        try {
          if (data.response.length > 0) {
            dispatch(setCustomKeywords({ data: data.response }));
            dispatch(setTotalCount({ xTotalCount: data.xTotalCount }));
          }
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    deleteSavedKeyword: build.mutation<void, number>({
      query: (savedKeywordId) => ({
        url: `/v1/keywords/saved/${savedKeywordId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['savedKeywords', 'DeleteKeyword', 'keywordGroups'],
    }),

    deleteCustomKeyword: build.mutation<void, number>({
      query: (id) => ({
        url: `/v1/keywords/custom/${id}`,
        method: 'DELETE',
      }),

      onQueryStarted: async (query, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(
            removeCustomKeyword({
              id: query,
            })
          );

          dispatch(
            keywordAPI.util.updateQueryData(
              'getNewCustomKeywords',
              'limit=5',
              (draft) => {
                const index = draft.findIndex(
                  (keyword) => keyword.customKeywordId === query
                );

                draft.splice(index, 1);
                return draft;
              }
            )
          );
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    getCategories: build.query<Categories[], void>({
      query: () => `/v1/categories`,
    }),

    updateKeywordNote: build.mutation<void, UpdateKeywordNote>({
      query: ({ savedKeywordId, note }) => ({
        url: `/v1/keywords/saved/${savedKeywordId}/note`,
        body: { note },
        method: 'PUT',
      }),
    }),

    removeSavedKeywordList: build.mutation<void, number>({
      query: (id) => ({
        url: '/v1/keywords/groups',
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: ['DeleteKeywordGroup'],
    }),

    getTrendingKeywords: build.query<TrendingKeyword[], void>({
      query: () => 'v1/keywords/trendings?limit=50',
    }),

    getNewCustomKeywords: build.query<CustomKeyword[], string>({
      query: (query) => ({
        url: `/v1/keywords/custom?${query}`,
        method: 'GET',
      }),

      providesTags: ['NewCustomKeywords'],
    }),
  }),
});

export const {
  useGetKeywordsQuery,
  useGetMoreKeywordsWithStatsQuery,
  useGetSavedKeywordsSummaryQuery,
  useGetPopularKeywordsQuery,
  useGetKeywordsWithStatsQuery,
  useGetSavedKeywordsQuery,
  useCreateCustomKeywordMutation,
  useGetCustomKeywordsQuery,
  useDeleteSavedKeywordMutation,
  useDeleteCustomKeywordMutation,
  useGetCategoriesQuery,
  useUpdateKeywordNoteMutation,
  useRemoveSavedKeywordListMutation,
  useGetTrendingKeywordsQuery,
  useGetNewCustomKeywordsQuery,
  useLazyGetKeywordsWithStatsQuery,
} = keywordAPI;
