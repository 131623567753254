import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import Cookies from 'js-cookie';

import LoggedOut from '@/util/log-out';
import awsBaseQuery from './aws.api';
import baseQuery, { cookieConfig } from './dev.nexLev';

export type AuthenticationResult = {
  AuthenticationResult: {
    AccessToken: string;
    refreshToken: string;
  };
};

// create a new mutex
const mutex = new Mutex();

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  const err: any = result.error;

  if (
    err &&
    (err?.originalStatus === 401 ||
      err.data === 'Invalid Access Token' ||
      err.data === 'Access Token has expired' ||
      err.data === 'Access Token has been revoked')
  ) {
    // checking whether the mutex is locked

    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // get email and refresh token from state

        const username = Cookies.get('account_id');
        const refreshToken = Cookies.get('refreshToken');

        // send put request to with refreshToken and email to get new accessToken
        const refreshResult = await baseQuery(
          {
            url: '/v1/auth/token',
            method: 'PUT',
            body: { username, refreshToken },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          const { AuthenticationResult } =
            refreshResult.data as AuthenticationResult;

          Cookies.set(
            'accessToken',
            AuthenticationResult?.AccessToken,
            cookieConfig
          );

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          LoggedOut();
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  } else if (result.error && result.error.status === 500) {
    result = await awsBaseQuery(args, api, extraOptions);
  }

  return result;
};

const baseAPI = createApi({
  tagTypes: [
    'auth',
    'WatchList',
    'UpdateUser',
    'keywordGroups',
    'savedKeywords',
    'savedChannels',
    'ChannelGroup',
    'highlightChannel',
    'CustomKeywords',
    'DeleteChannel',
    'DeleteKeyword',
    'NewCustomKeywords',
    'DeleteCustomKeyword',
    'DeleteChannelGroup',
    'DeleteKeywordGroup',
    'UpdateChannelGroup',
    'UpdateKeywordGroup',
    'BlackListedChannels',
    'Chat',
    'Users',
    'filterTemplates',
  ],
  baseQuery: baseQueryWithReauth,
  reducerPath: 'API',
  endpoints: () => ({}),
  refetchOnReconnect: true,
  refetchOnFocus: true,
});

export default baseAPI;
