import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import CrossIcon from '@/assets/icons/cross-icon.svg';
import Spinner from '../spinner/spinner.component';

interface IProps {
  title?: string;
  subtitle1?: string;
  subtitle2?: string;
  btnTxt?: string;
  isOpen: boolean;
  isLoading?: boolean;
  close: () => void;
  action?: () => void;
}

const AlertModal = ({
  title,
  subtitle1,
  subtitle2,
  btnTxt,
  isOpen,
  isLoading,
  close,
  action,
}: IProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-[35px] bg-white p-10 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end items-end mb-2">
                  <button type="button" onClick={close}>
                    <img
                      className="w-3 h-3"
                      src={CrossIcon}
                      alt="Alert Modal Cross Icon"
                    />
                  </button>
                </div>

                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{subtitle1}</p>
                  <p className="text-sm text-gray-500">{subtitle2}</p>
                </div>

                <div className="flex justify-evenly items-center gap-3 mt-4">
                  <button
                    type="button"
                    data-testid="alert-confirm-button"
                    className="active:opacity-[60%] rounded-full border border-transparent bg-skycyan px-4 py-1.5 text-sm font-medium text-black focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 w-full flex flex-items-center justify-center gap-2"
                    onClick={action || close}
                    disabled={isLoading}
                  >
                    {btnTxt} {isLoading ? <Spinner /> : null}
                  </button>
                  <button
                    type="button"
                    data-testid="alert-cancel-button"
                    className="rounded-full border border-transparent bg-[black] px-4 py-1.5 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 w-full"
                    onClick={close}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AlertModal;
