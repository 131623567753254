const FallbackComponent = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <div className="text-xl font-semibold">Oops! Something went wrong.</div>
        <div className="text-gray-500">Please try again later.</div>
      </div>
    </div>
  );
};

export default FallbackComponent;
