import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FilterContext from './filter-context';

type Props = {
  children: React.ReactNode;
};

function FilterProvider({ children }: Props) {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const memoizedValue = useMemo(() => {
    const newParams = new URLSearchParams(searchParams);

    const keywordId = newParams.get('keywordId');
    const name = newParams.get('name');

    const setParams = (key: string, value?: string) => {
      if (value) {
        newParams.set(key, value);
      } else {
        newParams.delete(key);
      }

      if (keywordId && name) {
        newParams.set('keywordId', keywordId);
        newParams.set('name', name);
      }
    };

    const appendParams = (key: string, value: string) => {
      newParams.append(key, value);
      if (keywordId && name) {
        newParams.set('keywordId', keywordId);
        newParams.set('name', name);
      }
    };

    return {
      newParams,
      searchParams,
      setSearchParams,
      setParams,
      loading,
      setLoading,
      appendParams,
    };
  }, [loading, searchParams, setSearchParams]);

  return (
    <FilterContext.Provider value={memoizedValue}>
      {children}
    </FilterContext.Provider>
  );
}

export default FilterProvider;
