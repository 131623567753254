import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import HighlightedChannelsIcon from '@/assets/icons/highlighted-channels-icon';
import useFilterContext from '@/features/filter/use-filter-context';
import SidebarConfig, { ActiveMenuItem } from '../../sidebar.config';
import navItems from '../menu/nav-items';

interface IAppBarProps {
  activeRoute: string;
}

const AppBar = ({ activeRoute }: IAppBarProps) => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(0);
  const { newParams } = useFilterContext();

  useEffect(() => {
    setActiveItem(SidebarConfig.getMenuItem(activeRoute));
  }, [activeRoute]);

  const handleMenuItemClick = (item: ActiveMenuItem, link: string) => {
    setActiveItem(item);

    const chHasShorts = newParams.get('chHasShorts');
    const chMonetized = newParams.get('chMonetized');

    if (!chHasShorts) newParams.set('chHasShorts', 'false');
    if (!chMonetized) newParams.set('chMonetized', 'true');

    if (link === '/keywords') {
      newParams.set('sortBy', '-filtered_keyword_total_channel_count');
      const linkWithParams = `${link}?${newParams.toString()}`;
      navigate(linkWithParams);
    } else if (link === '/channels') {
      newParams.set('sortBy', 'ch_first_video_upload_date');
      const linkWithParams = `${link}?${newParams.toString()}`;
      navigate(linkWithParams);
    } else if (link === '/saved') {
      const linkWithParams = `${link}?${newParams.toString()}`;
      navigate(linkWithParams);
    } else {
      navigate(link);
    }

    window.scrollTo(0, 0);
  };

  return (
    <div className="w-full z-40 h-20 md:hidden flex justify-evenly items-center sticky bottom-0 bg-white">
      {navItems.map((item, index) => (
        <div
          key={item.id}
          role="presentation"
          onClick={() => {
            if (item.activeItem === undefined) return;
            handleMenuItemClick(item.activeItem, item.link);
          }}
        >
          <div className="flex justify-center items-center">
            <item.Icon stroke={activeItem === index ? '#00D5E0' : '#524F4D'} />
            <HighlightedChannelsIcon
              className={classNames(
                {
                  hidden: item.id !== 'ai-niche-finder',
                },
                '-ml-2.5'
              )}
              stroke="white"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppBar;
