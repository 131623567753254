import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';

interface IAvatarProps {
  onClick?: () => void;
  img?: string;
  icon?: string;
  avatarArea?: string;
  size?: string;
  iconPosition?: string;
  userName: string;
}

const AvatarComponent = ({
  img,
  icon,
  avatarArea,
  size,
  iconPosition,
  userName,
  onClick,
}: IAvatarProps) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      id="profile-avatar"
      className="p-1 bg-gradient-to-tr from-[#CFFF06] via-[#00D5E0] to-[#00007C] rounded-full relative"
      onClick={() => navigate('/profile-setting')}
    >
      {img && (
        <img
          className={`${avatarArea} rounded-full inline-block `}
          src={img}
          alt="Avatar"
        />
      )}
      {!img && (
        <Avatar
          name={userName}
          size={size || '50'}
          fgColor="cyan"
          color="black"
          round
        />
      )}
      {icon && (
        <img
          src={icon}
          className={`absolute top-[-5px] right-0 ${iconPosition}`}
          alt="Crown Icon"
        />
      )}
    </button>
  );
};

export default AvatarComponent;
