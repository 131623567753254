import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import partyIcon from '@/assets/icons/party-icon.svg';
import RightArrow from '@/assets/icons/wright-arrow-white.svg';
import Logo from '@/assets/nexlev-logo.svg';

const PaymentSuccessModal = () => {
  const navigate = useNavigate();

  return (
    <Transition appear show as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={() => navigate(-1)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-[35px] bg-[#E8FFEF] p-[93px] text-left align-middle shadow-xl transition-all">
                <div className="flex flex-col items-center">
                  <img src={Logo} alt="logo" />
                  <img src={partyIcon} alt="party" className="mt-24" />
                  <h2 className="font-semibold text-4xl text-center mt-14">
                    Hurray, payment has been sent successfully!
                  </h2>
                  <p className="mt-5 text-[#524F4D]">
                    Anytime you can cancel the subscription
                  </p>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="bg-[#161313] text-white rounded-[35px] px-10 py-3 mt-10 inline-flex items-center gap-6"
                  >
                    <span>See Package</span>
                    <img src={RightArrow} alt="right arrow" />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PaymentSuccessModal;
