import { Dialog, Transition } from '@headlessui/react';
import Cookies from 'js-cookie';
import { Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import coinIcon from '@/assets/icons/coin.svg';
import XMarkIcon from '@/assets/icons/x-mark-icon';
import NotificationDropdown from '@/components/dropdown/notification-dropdown';
import SettingDropdown from '@/components/dropdown/setting-dropdown';
import { useGetUserQuery } from '@/features/auth/auth.api';

const PopupSetting = () => {
  const token = Cookies.get('accessToken');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { data: authUser } = useGetUserQuery(null, {
    skip: !token,
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const tooltipContent =
    pathname !== '/nexlev-ai'
      ? 'Each time you interact with RPM Predictor it will cost 50 credits'
      : 'Each time you interact with NexLev AI it will cost 200 credits';

  const CreditsWalletComp =
    pathname === '/nexlev-ai' || pathname === '/rpm-predictor' ? (
      <div className="flex flex-col gap-y-2 font-semibold text-gray-600 text-[10px] items-center -ml-3">
        <button
          type="button"
          title={tooltipContent}
          onClick={() => {
            navigate('/buy-credits');
            closeModal();
          }}
          className="bg-yellow-apple p-1 -mt-1 font-bold rounded-full flex items-center text-xs"
        >
          <img src={coinIcon} className="mr-1" alt="coin icon" />
          <span>{authUser?.credits}</span>
        </button>
        <span>Wallet</span>
      </div>
    ) : null;

  return (
    <>
      <button type="button" onClick={openModal}>
        <span>...</span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-start justify-center pt-[75px] text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full flex justify-around items-center max-w-md transform bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {CreditsWalletComp}

                  <div className="flex flex-col gap-y-2 font-semibold text-gray-600 text-[10px] items-center">
                    <NotificationDropdown />
                    <span>Notifications</span>
                  </div>
                  <div className="flex flex-col gap-y-2 font-semibold text-gray-600 text-[10px] items-center mr-3">
                    <SettingDropdown closeModal={closeModal} />
                    <span>Settings</span>
                  </div>

                  <button
                    type="button"
                    onClick={closeModal}
                    className="absolute top-[-35px] right-3"
                  >
                    <div className="w-12 h-12 rounded-full bg-black flex justify-center items-center">
                      <XMarkIcon stroke="#fff" height={20} width={20} />
                    </div>
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PopupSetting;
