import classNames from 'classnames';

import CuratedChannelIcon from '@/assets/icons/curated-ch-icon.svg';
import FeedbackIcon from '@/assets/icons/discord-icon.svg';

interface IMenuLinkItemProps {
  expanded: boolean;
  label: 'Curated Channels' | 'Send Feedback';
}

const MenuLinkItem = ({ expanded, label }: IMenuLinkItemProps) => {
  const handleFeedback = () => window.open(import.meta.env.VITE_FEEDBACK_LINK);
  const handleCuratedChannels = () =>
    window.open(import.meta.env.VITE_CURATED_CHANNELS_LINK);

  return (
    <div>
      <button
        type="button"
        className={classNames('flex items-center text-darkgray', {
          'space-x-3': expanded,
          'flex-col justify-center space-y-1': !expanded,
        })}
        onClick={
          label === 'Curated Channels' ? handleCuratedChannels : handleFeedback
        }
      >
        <img
          src={label === 'Curated Channels' ? CuratedChannelIcon : FeedbackIcon}
          alt="Curated Channel Icon"
          className="w-5 h-5"
        />
        <div
          className={classNames('font-semibold text-center antialiased', {
            'text-[0.8125rem]': expanded,
            'text-[0.625rem] px-0.5': !expanded,
          })}
        >
          {label}
        </div>
      </button>
    </div>
  );
};

export default MenuLinkItem;
