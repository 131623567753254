type Props = {
  className?: string;
  stroke?: string;
};

const HighlightedChannelsIcon = ({ className, stroke = '#161313' }: Props) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#00D5E0" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={stroke} />
      <path
        d="M8 10.625L4.90625 12.5L5.72656 8.98438L3.00781 6.61719L6.59375 6.3125L8 3.00781L9.40625 6.3125L12.9922 6.61719L10.2734 8.98438L11.0938 12.5L8 10.625Z"
        fill="white"
      />
    </svg>
  );
};

export default HighlightedChannelsIcon;
