import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';

const initialState: INexLevAIState = {
  hideRevealedChannels: false,
};

const NexLevAISlice = createSlice({
  name: 'NexLevAI',
  initialState,
  reducers: {
    setHideRevealedChannels: (state, action: PayloadAction<boolean>) => {
      state.hideRevealedChannels = action.payload;
    },
  },
});

export const { setHideRevealedChannels } = NexLevAISlice.actions;

export default NexLevAISlice.reducer;

export const selectNexLevAI = (state: RootState) =>
  state.NexLevAI.hideRevealedChannels;
