import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import coinIcon from '@/assets/icons/coin.svg';
import TooltipComp from '@/components/tooltip';

const CreditsWallet = ({
  creditsDeducate,
}: {
  creditsDeducate: number | undefined;
}) => {
  const prevCreditsRef = useRef<number | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    prevCreditsRef.current = creditsDeducate ?? prevCreditsRef.current;
  }, [creditsDeducate]);

  const tooltipContent =
    pathname !== '/nexlev-ai'
      ? 'Each time you interact with RPM Predictor it will cost 50 credits'
      : 'Each time you interact with NexLev AI it will cost 200 credits';

  if (pathname !== '/nexlev-ai' && pathname !== '/rpm-predictor') {
    return null;
  }

  return (
    <>
      <span className="font-bold text-gray-600 text-xs">Wallet</span>
      <TooltipComp
        tooltipContent={
          <p className="text-sm bg-[#161313] text-white p-2 rounded-md mx-2">
            {tooltipContent}
          </p>
        }
        tooltipTrigger={
          <button
            type="button"
            onClick={() => navigate('/buy-credits')}
            className="bg-yellow-apple px-4 py-3 font-bold rounded-full flex items-center text-xs"
          >
            <img
              src={coinIcon}
              className={`mr-2 ${
                creditsDeducate &&
                prevCreditsRef.current !== null &&
                creditsDeducate < prevCreditsRef.current
                  ? 'decreasing-animation'
                  : ''
              }`}
              alt="coin icon"
            />
            <span>{creditsDeducate}</span>
          </button>
        }
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
    </>
  );
};

export default CreditsWallet;
