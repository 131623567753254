import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetKeywordsQuery } from '@/features/keyword/keyword.api';
import KeywordSearchResult from '@/features/search-results/keyword-search-results';
import SearchButton from '@/features/search-results/search-button';
import useDebounce from '@/hooks/use-debounce.hook';
import SelectComponent from '../dropdown/select-component';
import { SelectType } from '../dropdown/type';

interface ISearchInputProps {
  placeholder?: string;
  children?: string;
  selectType?: SelectType<string>;
  setSelectType?: Dispatch<SetStateAction<SelectType<string>>>;
  selectOptions?: SelectType<string>[];
  rounded?: string;
  buttonTxt?: string;
  height?: string;
  onChange?: (value: string) => void;
  onSearch?: () => void;
  autoComplete?: string;
}

const SearchInput = ({
  placeholder = '',
  children,
  selectType,
  setSelectType,
  selectOptions = [],
  onChange = () => {},
  onSearch = () => {},
  rounded = 'custom-lg',
  buttonTxt,
  height = '',
  autoComplete = 'true',
}: ISearchInputProps) => {
  const [value, setValue] = useState(children ?? '');
  const [selected, setSelected] = useState<string>('');
  const [open, setOpen] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [query, setQuery] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: keywordData,
    isLoading,
    isFetching,
  } = useGetKeywordsQuery(query, {
    skip: !query,
  });

  const isKeyword = selectType?.value === 'keyword';
  const isChannel = selectType?.value === 'channel';

  useEffect(() => {
    setValue('');
    setSelected('');
    setSelectedIndex(-1);
  }, [isKeyword, isChannel]);

  useEffect(() => {
    if (isKeyword && value !== selected) {
      setSelected('');
      setOpen(true);
    }
    if (isChannel) {
      setSelected(value);
      setOpen(false);
      setUrl(`/channels?chName=${selected.toLowerCase()}`);
    }
  }, [isChannel, isKeyword, selected, value]);

  useEffect(() => {
    setOpen(false);
    setValue('');
  }, [location.pathname]);

  const debouncedHandleChange = useDebounce((queryText: string) => {
    setValue(queryText.trim() !== '' ? queryText : '');
    onChange(queryText.toLowerCase());
    if (isKeyword) {
      setQuery(`page=1&limit=10&nameStartsWith=${queryText.toLowerCase()}`);
    }
  }, 50);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedHandleChange(e.target.value);
  };

  const handleClick = () => {
    navigate(url);
    onSearch();
    setValue('');
    setSelected('');
    setSelectedIndex(-1);
  };

  const handelOnClicked = (label: SearchKeyword) => {
    setOpen(false);
    navigate(
      `/channels?name=${label.name}&keywordId=${label.keyword_id}&sortBy=ch_first_video_upload_date&chMonetized=true`
    );
    onSearch();
    setValue('');
    setSelectedIndex(-1);
  };

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    const ARROW_UP_KEY = e.key === 'ArrowUp';
    const ARROW_DOWN_KEY = e.key === 'ArrowDown';
    const ENTER_KEY = e.key === 'Enter';

    if ((ARROW_UP_KEY || ARROW_DOWN_KEY) && keywordData) {
      e.preventDefault();
      const nextIndex = ARROW_UP_KEY
        ? Math.max(0, selectedIndex - 1)
        : Math.min(keywordData.length - 1, selectedIndex + 1);

      setSelectedIndex(nextIndex);
      setValue(keywordData[nextIndex].name);
      setSelected(keywordData[nextIndex].name);

      const newUrl = `/channels?name=${keywordData[nextIndex].name}&keywordId=${keywordData[nextIndex].keyword_id}&sortBy=ch_first_video_upload_date&chMonetized=true`;
      setUrl(newUrl);
    }

    if (ENTER_KEY && selected) {
      handleClick();
      e.preventDefault();
    }
  }

  const handleOnFocus = () => setOpen(true);
  const handleOnBlur = () => setTimeout(() => setOpen(false), 400);

  return (
    <div
      data-testid="search-bar"
      className="w-full sm:w-[75%] flex flex-col justify-center align-middle"
    >
      <div className="flex flex-row relative items-center justify-between">
        {selectType && setSelectType && selectOptions && (
          <SelectComponent
            selectedOption={selectType}
            setSelectedOption={setSelectType}
            options={selectOptions}
            className="absolute left-2 z-100 border-r w-28"
          />
        )}

        <input
          type="search"
          value={value}
          autoComplete={autoComplete}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          className={`form-control block w-full pl-${
            selectType ? '32' : '3'
          } pr-12 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border rounded-${rounded} h-${height} transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-600 focus:outline-none`}
          placeholder={placeholder}
          onFocus={isKeyword ? handleOnFocus : undefined}
          onBlur={isKeyword ? handleOnBlur : undefined}
        />

        <SearchButton
          selected={selected}
          buttonTxt={buttonTxt}
          onClick={handleClick}
        />

        {isKeyword && value?.length !== 0 && open && (
          <div className="absolute top-11 z-100 bg-white w-full rounded-lg py-4 max-h-56 overflow-y-auto transition-all">
            <KeywordSearchResult
              keywordData={keywordData ?? []}
              isLoading={isLoading || isFetching}
              selected={selected}
              selectedIndex={selectedIndex}
              onClick={handelOnClicked}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
