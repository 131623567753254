/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-regex-literals */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */

import classNames from 'classnames';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';

import ErrorAlert from '@/components/error-alert/error-alert.component';
import Spinner from '@/components/spinner/spinner.component';
import { useVerifyNewEmailMutation } from '../auth/auth.api';

type Props = {
  value: string;
  valueLength: number;
  onChange: (value: string) => void;
};

function OtpInput({ value, valueLength, onChange }: Props) {
  const RE_DIGIT = new RegExp(/^\d+$/);
  const indexes = [1, 2, 3, 4, 5, 6];

  const [valueItems, setValueItems] = useState<string[]>([]);

  useEffect(() => {
    const valueArray = value.split('');
    const items: Array<string> = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    setValueItems(items);
  }, [value, valueLength]);

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };
  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const { target } = e;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }
  };
  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }

    focusToPrevInput(target);
  };
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    // keep focusing back until previous input
    // element has value
    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className="flex justify-center items-center space-x-3">
      {valueItems.map((digit, idx) => (
        <Fragment key={`code-digit-${indexes[idx]}`}>
          <input
            id={indexes[idx].toString()}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{1}"
            maxLength={1}
            className="w-12 h-12 border-2 border-gray-400 bg-transparent outline-none text-center font-semibold text-xl spin-button-none focus:border-gray-700 focus:text-gray-700 text-gray-400 transition rounded-[30px]"
            value={digit}
            onChange={(e) => inputOnChange(e, idx)}
            onKeyDown={inputOnKeyDown}
            onFocus={inputOnFocus}
          />
        </Fragment>
      ))}
    </div>
  );
}

interface IConfirmEmailModal {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  // setSuccess: Dispatch<SetStateAction<boolean>>,
}

const ConfirmEmailModal = ({ show, setShow }: IConfirmEmailModal) => {
  const [code, setCode] = useState<string[]>(new Array(6).fill(''));
  const [otp, setOtp] = useState('');
  const onChange = (value: string) => setOtp(value);
  const [verifyNewEmail, { isSuccess, error, isLoading }] =
    useVerifyNewEmailMutation();
  const indexes = [1, 2, 3, 4, 5, 6];
  const [err, setErr] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleClose = () => {
    setShow(false);
    setCode(new Array(6).fill(''));
    setOtp('');
    setErr(false);
  };
  const handleConfirm = async () => {
    const finalCode = otp.replaceAll(' ', '');

    if (finalCode.length === 6) {
      await verifyNewEmail(finalCode);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      handleClose();
    }
  }, [isSuccess]);
  useEffect(() => {
    if (error && code[0].length) {
      setCode(['', '', '', '', '', '']);
      setOtp('');
      setErr(true);
    }
  }, [error]);
  return (
    <div>
      <div
        className={classNames(
          'fixed bg-gray-600 z-[100] bg-opacity-50 inset-0 h-[100%] w-[100%]',
          { hidden: !show }
        )}
      >
        <div className="relative bg-white rounded-3xl shadow h-[280px] w-[97%] md:w-[450px] top-[15%] left-[8px]  md:left-[35%] ">
          <div className="p-5 flex flex-col gap-5">
            <div className="mt-4 text-center font-bold text-lg text-darkgray">
              We&apos;ve sent you the verification code to your new email.{' '}
            </div>
            <div className=" mb-3">
              <div className="flex flex-col gap-2">
                <div className="flex justify-center items-center space-x-3">
                  <OtpInput value={otp} valueLength={6} onChange={onChange} />
                </div>
                {err && (
                  <ErrorAlert
                    message="Incorrect code. Please try again."
                    color="text-orange-500"
                  />
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3 w-full gap-5 mt-3 mb-3 px-1">
              <button
                type="button"
                onClick={handleConfirm}
                className="active:opacity-[60%] px-6 py-2.5 bg-skycyan rounded-full text-sm font-semibold shadow-md flex flex-row h-8 items-center justify-center"
              >
                {isLoading && (
                  <div className="mr-3">
                    <Spinner />
                  </div>
                )}
                <div>Confirm</div>
              </button>
              <button
                type="button"
                onClick={handleClose}
                className="px-6 py-2.5 bg-black text-white rounded-full text-sm font-semibold shadow-md flex flex-row h-8 items-center justify-center"
              >
                <div>Cancel</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfirmEmailModal;
