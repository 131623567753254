import { Outlet } from 'react-router-dom';

import Logo from '@/assets/nexlev-logo.svg';

const LINEAR_GRADIENT =
  'linear-gradient(135deg, #d7f9fb 0%, #ffffff 50%, #f7ffd9 100%)';

const AuthenticationLayout = () => {
  return (
    <div
      style={{ backgroundImage: LINEAR_GRADIENT }}
      className="w-full min-h-screen relative flex flex-col justify-center items-center"
    >
      <div className="absolute top-5">
        <img
          src={Logo}
          alt="nexlev logo"
          className="w-[8.25rem] h-[2.875rem] m-auto"
        />
      </div>
      <div className="w-full max-w-3xl m-auto p-6">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthenticationLayout;
