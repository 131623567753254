import MenuItem from './menu-item.component';
import navItems from './nav-items';

interface IMenuProps {
  expanded: boolean;
}

const Menu = ({ expanded }: IMenuProps) => {
  return (
    <div className="w-full flex flex-col space-y-1 xxl:space-y-3">
      {navItems.map((item) => (
        <MenuItem key={item.id} item={item} expanded={expanded} />
      ))}
    </div>
  );
};

export default Menu;
