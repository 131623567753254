import api_icon from '../assets/icons/api_icon.svg';
import refresh from '../assets/icons/refresh.svg';

const ErrorPage = () => {
  return (
    <div className="w-full bg-[#ECECF5] p-6 md:p-12">
      <div className="flex justify-evenly items-center flex-col-reverse md:flex-row bg-white rounded-3xl h-[calc(100vh-3rem)] md:h-[calc(100vh-6rem)]">
        <div className="max-w-[400px] px-4 flex flex-col items-center md:items-start">
          <h1 className="text-[#FF6006] font-bold text-4xl md:text-7xl">500</h1>
          <h2 className="text-[#161313] font-bold text-xl md:text-3xl my-3">
            Internal Server Error
          </h2>
          <p className="text-[#524f4d98] text-base md:text-lg text-justify md:text-start">
            We are facing an issue to receive the result do to bad getaway,
            refresh the page or close and open it again.
          </p>
          <button
            type="button"
            className="bg-[#161313] text-white rounded-full px-5 md:px-8 py-2 md:py-3 mt-4 flex items-center space-x-2 md:space-x-3 text-[12px] hover:bg-[#161313]"
            onClick={() => window.location.reload()}
          >
            <img className=" w-3 h-3" src={refresh} alt="refresh icon" />
            <span>Refresh</span>
          </button>
        </div>
        <img
          className="w-40 h-40 md:w-96 md:h-96"
          src={api_icon}
          alt="API Error"
        />
      </div>
    </div>
  );
};

export default ErrorPage;
