import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies, { CookieAttributes } from 'js-cookie';

import getCurrentStage from '../util/env.util';

const stage = getCurrentStage();

const baseUrl =
  stage === 'development'
    ? 'https://dev.api.nexlev.io/'
    : 'https://api.nexlev.io';

export const cookieConfig: CookieAttributes = {
  secure: true,
  sameSite: 'strict',
  expires: 7,
};

//  for safari to work cookie properly in localhost (without ssl), we need to set secure=false and sameSite='none',
//  uncomment the cookie config for safari development, but be careful this config will break in chrome, so don't forget to revert the cookie config after safari development

// export const cookieConfig: CookieAttributes = {
//    secure: stage !== 'development',
//    sameSite: stage === 'development' ? 'none' : 'strict',
//    expires: 7,
//  };

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = Cookies.get('accessToken');
    if (token) headers.set('authorization', `Bearer ${token}`);

    return headers;
  },
});

export default baseQuery;
