import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import ArrowIcon from '@/assets/icons/arrow-icon';
import crown from '@/assets/icons/crown-icon.svg';
import infoIcon from '@/assets/icons/info-icon.svg';
import LogoSmall from '@/assets/nexlev-logo-sm.svg';
import Logo from '@/assets/nexlev-logo.svg';
import AvatarComponent from '@/components/avatar.component';
import { useGetUserQuery } from '@/features/auth/auth.api';
import ConfirmEmailModal from '@/features/profile/confirm-email-modal.component';
import cn from '@/util/cn.utils';
import Menu from './components';
import AppBar from './components/app-bar/app-bar.component';
import MenuLinkItem from './components/menu/menu-link-item';
import SidebarConfig from './sidebar.config';

const SidebarLayout = () => {
  const token = Cookies.get('accessToken');
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = useGetUserQuery(null, {
    skip: !token,
  });
  const [expanded, setExpanded] = useState(false);
  const [showVerify, setShowVerify] = useState(false);

  useEffect(() => {
    setExpanded(SidebarConfig.getSidebarExpandedState(location.pathname));
  }, [location.pathname]);

  return (
    <div className="flex flex-col md:flex-row">
      <ConfirmEmailModal show={showVerify} setShow={setShowVerify} />
      {data && String(data?.email_verified) === 'false' && (
        <button
          type="button"
          onClick={() => setShowVerify(true)}
          className="absolute z-100 top-0 h-[45px] shadow-lg w-full bg-yellow-300 flex flex-row md:justify-between justify-center rounded-md"
        >
          <div className="p-3 hidden md:block">
            <ArrowIcon />
          </div>

          <div className="flex flex-row gap-1 font-semibold text-darkgray opacity-[60%] p-2.5 text-sm">
            <img src={infoIcon} alt="info" />
            Please Click here to verify your Email
          </div>
          <div className="p-3 hidden md:block">
            {/* <img src={infoIcon} alt="info" /> */}
            <ArrowIcon />
          </div>
        </button>
      )}
      <aside
        className={classNames(
          'h-screen w-[14%] hidden md:flex flex-col justify-start items-center space-y-4 xxl:space-y-10 py-2 sticky top-0 overflow-y-auto overflow-x-hidden no-scrollbar',
          {
            'w-[7%] bg-nexlev-200': !expanded,
            ' bg-white ': expanded,
          }
        )}
      >
        <button type="button" onClick={() => navigate('/')}>
          <img src={expanded ? Logo : LogoSmall} className="w-32" alt="Logo" />
        </button>
        <div className="flex flex-col space-y-2 items-center text-center">
          <AvatarComponent
            onClick={() => navigate('/profile-setting')}
            avatarArea={`${expanded ? 'sm:h-20 sm:w-20' : 'w-12 h-12'}`}
            icon={crown}
            iconPosition={`${expanded ? 'sm:w-7 sm:h-7' : 'w-5 h-5'}`}
            img={data?.picture}
            userName={`${data?.name} ${data?.family_name}`}
          />

          <div
            className={classNames('flex flex-col', { 'md:hidden': !expanded })}
          >
            <span className="text-xs">Welcome back,</span>
            <span className="text-sm font-bold">{data?.name || 'Martin'} </span>
          </div>
        </div>
        <Menu expanded={expanded} />
        <div
          className={cn(
            `w-full md:flex items-end h-full hidden`,
            !expanded ? 'justify-center' : 'justify-start ml-8'
          )}
        >
          <div
            className={cn(
              `flex flex-col gap-5 py-3 w-full justify-start  `,
              !expanded ? 'items-center' : 'items-start'
            )}
          >
            <MenuLinkItem expanded={expanded} label="Curated Channels" />
            <MenuLinkItem expanded={expanded} label="Send Feedback" />
          </div>
        </div>
      </aside>
      <main
        className={cn(
          'bg-nexlev-100 min-h-fit w-full',
          expanded ? 'md:w-[calc(100%-14%)]' : 'md:w-[calc(100%-7%)]'
        )}
      >
        <Outlet />
      </main>
      <AppBar activeRoute={location.pathname} />
    </div>
  );
};

export default SidebarLayout;
