import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/store';

const initialState: ChannelGroupState = {
  loading: false,
  ChannelGroup: [],
  activeChGroup: null,
};

const ChannelGroupSlice = createSlice({
  name: 'ChannelGroup',
  initialState,
  reducers: {
    setChannelGroup: (state, action: PayloadAction<ChannelGroup[]>) => {
      state.ChannelGroup = action.payload;

      if (!state.activeChGroup) {
        state.activeChGroup = action.payload?.[0]?.channel_group_id;
      }
    },

    addChannelGroup: (state, action: PayloadAction<ChannelGroup>) => {
      state.ChannelGroup.push(action.payload);
    },

    removeChannelGroup: (state, action: PayloadAction<number>) => {
      state.ChannelGroup = state.ChannelGroup.filter(
        (channel) => channel.channel_group_id !== action.payload
      );

      if (state.activeChGroup === action.payload) {
        state.activeChGroup = state.ChannelGroup?.[0]?.channel_group_id;
      }
    },

    updateChannelGroup: (state, action: PayloadAction<UpdateGroup>) => {
      const index = state.ChannelGroup.findIndex(
        (channel) => channel.channel_group_id === action.payload.id
      );

      state.ChannelGroup[index] = {
        ...state.ChannelGroup[index],
        name: action.payload.name,
      };
    },

    setActiveChGroup: (state, action: PayloadAction<number | null>) => {
      state.activeChGroup = action.payload;
    },

    setChannelLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setChannelGroup,
  addChannelGroup,
  setActiveChGroup,
  setChannelLoading,
  removeChannelGroup,
  updateChannelGroup,
} = ChannelGroupSlice.actions;

export default ChannelGroupSlice.reducer;

export const selectChannelGroup = (state: RootState) => state.ChannelGroup;
