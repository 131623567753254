/* eslint-disable react-hooks/exhaustive-deps */

import { Menu, Transition } from '@headlessui/react';
import Cookies from 'js-cookie';
import { Fragment, useEffect, useState } from 'react';

import bellIcon from '@/assets/icons/bell.svg';
import DeleteIcon from '@/assets/icons/delete-icon';
import likeIcon from '@/assets/icons/new-notification.svg';
import { useGetUserQuery } from '@/features/auth/auth.api';
import useBreakpoint from '@/hooks/use-breakpoint.hook';

interface Notification {
  id: number;
  title: string;
  description: string;
  read: boolean;
  time: string;
  icon: string;
}

export default function NotificationDropdown() {
  const token = Cookies.get('accessToken');

  const { isSm } = useBreakpoint('sm');
  const { data: authUser } = useGetUserQuery(null, {
    skip: !token,
  });

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const capitalizeMsg = (msg: string) => {
    return msg.slice(0, 1).toUpperCase() + msg.slice(1);
  };

  const handleDelete = () => {
    setNotifications([]);
  };

  const lowCredNotif: Notification = {
    id: 1,
    title: 'Your credits are running low.',
    description:
      'you have less than 100 credits, you might want to consider re-purchasing.',
    read: false,
    time: 'now',
    icon: 'icon',
  };

  const [lock, setLock] = useState(false);
  useEffect(() => {
    if (
      !notifications.some(
        (notification) => notification.id === lowCredNotif.id
      ) &&
      !lock
    ) {
      if (authUser && authUser.credits <= 100) {
        setLock(true);

        setNotifications((prev) => [...prev, lowCredNotif]);
      }
    }
  }, [authUser]);

  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="hover:cursor-pointer w-4 relative">
          <div className="bg-sunset text-white text-xs left-1 bottom-3  absolute inline-flex justify-center items-center rounded-full h-5 w-5">
            <span>{notifications?.length}</span>
          </div>
          <img src={bellIcon} alt="Bell icon" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`z-[100] absolute ${
              isSm ? 'right-0' : 'left-0 translate-x-[-27%]'
            }  mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            <div className="flex justify-between p-4 items-center">
              <p className="font-bold text-gray-500">Notification</p>
              <button
                onClick={handleDelete}
                type="button"
                className={`${notifications.length === 0 ? 'hidden' : 'flex'}`}
              >
                <DeleteIcon />
              </button>
            </div>
            <div
              className={`${
                notifications.length === 0 ? 'h-[60px] ' : 'h-[250px]'
              }  overflow-y-auto`}
            >
              {notifications &&
                notifications.map((notification) => (
                  <Menu.Item key={notification.id}>
                    {({ active }) => (
                      <div
                        className={`${active && 'bg-gray-200 cursor-pointer'} ${
                          !notification.read && 'bg-gray-100'
                        } flex justify-between items-center p-2`}
                      >
                        <div className="flex justify-between items-center">
                          <img
                            src={likeIcon}
                            className="w-10 h-10"
                            alt="icon"
                          />

                          <div className="ml-4">
                            <p className="font-bold text-gray-500">
                              {notification.title}
                            </p>

                            <p className="text-gray-400 text-xs">
                              {capitalizeMsg(notification.description)}
                            </p>
                            <p className="text-gray-500 font-medium text-xs">
                              {notification.time}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              {notifications.length === 0 && (
                <div className="text-[0.8rem] text-gray-600 text-center p-5">
                  You don&apos;t have any new notifications.
                </div>
              )}
            </div>

            <p className="py-3 cursor-pointer hover:bg-gray-100 rounded-md font-medium text-sm text-gray-500 text-center">
              Show All Notification
            </p>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
