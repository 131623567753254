import SearchIcon from '@/assets/icons/search.svg';
import Button from '@/components/buttons/button.component';

interface ISearchButtonProps {
  selected: string;
  buttonTxt?: string;
  onClick?: () => void;
}

const SearchButton = ({ selected, buttonTxt, onClick }: ISearchButtonProps) => {
  return (
    <>
      {!buttonTxt && (
        <div
          className={`absolute right-2 w-8 h-8 inline-flex ${
            selected ? 'bg-skycyan' : 'bg-[#D9D9D9]'
          } rounded-[50px]`}
        >
          <button
            type="button"
            data-testid="search-button"
            disabled={!selected}
            className={`p-2.5 gap-2.5 inline-flex justify-center items-center ${
              !selected && 'cursor-not-allowed'
            }`}
            onClick={onClick}
          >
            <img src={SearchIcon} alt="Search Icon" />
          </button>
        </div>
      )}
      {buttonTxt && (
        <div className="absolute right-2 bottom-[25%]  inline-flex">
          <Button
            text={buttonTxt}
            bgColor="bg-black"
            textColor="white"
            rounded="custom-md"
            onClickHandle={onClick}
          />
        </div>
      )}
    </>
  );
};

export default SearchButton;
