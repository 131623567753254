import baseAPI from '@/store/base.api';

export const userAPI = baseAPI.injectEndpoints({
  endpoints: (build) => ({
    createPortalSession: build.mutation<null, CreatePortalSessionCredential>({
      query: (credentials) => ({
        url: '/v1/users/portal-session',
        method: 'POST',
        body: credentials,
      }),

      onQueryStarted: async (credentials, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error: any) {
          const url = error?.error?.data;
          if (url) window.location.href = url;
        }
      },
    }),

    createCheckoutSession: build.mutation<
      null,
      CreateCheckoutSessionCredential
    >({
      query: (credentials) => ({
        url: '/v1/users/checkout-session',
        method: 'POST',
        body: credentials,
      }),

      onQueryStarted: async (credentials, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error: any) {
          const url = error?.error?.data;
          if (url) window.location.href = url;
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreatePortalSessionMutation,
  useCreateCheckoutSessionMutation,
} = userAPI;
