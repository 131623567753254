import { useEffect, useState } from 'react';

const useUserLocation = () => {
  const [location, setLocation] = useState<string | null>(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setLocation(`${latitude},${longitude}`);
      localStorage.setItem('userLocation', `${latitude},${longitude}`);
    });
  }, []);

  return location;
};

export default useUserLocation;
