import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';

export interface AuthState {
  email: string;
  password: string;
}

const initialState: AuthState = {
  email: '',
  password: '',
};

const credentialSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials(
      state,
      action: PayloadAction<{ email: string; password: string }>
    ) {
      state.email = action.payload.email;
      state.password = action.payload.password;
    },

    resetCredentials(state) {
      state.email = '';
      state.password = '';
    },
  },
});

export const { setCredentials, resetCredentials } = credentialSlice.actions;

export default credentialSlice.reducer;

export const currentCredentials = (state: RootState) => state.credential;
