import { Listbox, Transition } from '@headlessui/react';
import { Dispatch, Fragment, SetStateAction } from 'react';

import ArrowIconSrc from '@/assets/icons/down-arrow.svg';
import { SelectType } from './type';

interface SelectComponentProps<T> {
  selectedOption: SelectType<T>;
  setSelectedOption: Dispatch<SetStateAction<SelectType<T>>>;
  options: SelectType<T>[];
  className?: string;
}

export default function SelectComponent<T>({
  selectedOption,
  setSelectedOption,
  options,
  className = 'w-72',
}: SelectComponentProps<T>) {
  return (
    <div className={className}>
      <Listbox value={selectedOption} onChange={setSelectedOption}>
        {({ open }) => (
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-pointer rounded-lg py-1.5 px-2 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">{selectedOption?.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <img
                  src={ArrowIconSrc}
                  alt="arrow-icon"
                  className={`h-3 w-3 transition-all ${
                    open ? 'transform rotate-180' : ''
                  }`}
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {options.map((item) => (
                  <Listbox.Option
                    key={item.value as string}
                    className={({ active }) =>
                      `relative cursor-pointer select-none border-b last:border-0 py-1.5 px-2 ${
                        active ? 'bg-nexlev-100 text-black' : 'text-gray-900'
                      }`
                    }
                    value={item}
                  >
                    {({ selected }) => (
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {item.name}
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
}
